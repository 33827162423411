<template>
<div>
      <b-card no-body class="cardStore"  border-variant="primary">
          <b-card-header class="p-1">
            <b-container fluid>
            <b-row>
              <b-col>
                <div class="text-left mt-1">
            <b-card-title>{{$t("curlang")=='en'?item.title:item.titlees}}</b-card-title>
            </div>
              </b-col>
            </b-row>
            </b-container>
          </b-card-header>
          <b-card-body>
            <div v-if="item.type.value==1 || item.type.value==2 || item.type.value==4"  class="mb-1">
                  <b-card-text v-html="$t('curlang')=='en'?item.description:item.descriptiones">
              </b-card-text>
            </div>
            <div v-if="item.type.value==3 && message" class="mb-1">
                <b-card-text >
                {{$t("curlang")=='en'?item.description:item.descriptiones}}
        </b-card-text>
    <b-form-group class="mb-1">
      <b-form-textarea
        v-model="message"
        rows="3"
      />
    </b-form-group>
    <div class="text-right">
       <b-button
       size="sm"
      v-clipboard:copy="message"
      v-clipboard:success="onCopy"
      v-clipboard:error="onError"
      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
      variant="primary"
    >
      {{$t("curlang")=='en'?'Copy!':'Copiar'}}
    </b-button>
    </div>
   
     </div>
        <div v-if="rewards.length==0"  @click="openmodal=true" >
           <h3>
       <b-badge variant="light-secondary" class="badgeCrown1 cursor-pointer">
     {{item.rewards+" "}}<img :src="item.typeCoin.value==1?require('@/assets/playground_assets/crowngray.png'):require('@/assets/playground_assets/gobletgray.png')" style="margin-top:-5px;">
      </b-badge>
      </h3>
        </div>
        <div v-if="rewards.length>0 && rewards[0].status=='pending'" style=" display: flex;justify-content: space-between;">
          <div class="text-left"  @click="openmodal=true" >
           <h3>
       <b-badge variant="light-secondary" class="badgeCrown1 cursor-pointer">
      {{item.rewards+" "}}<img :src="item.typeCoin.value==1?require('@/assets/playground_assets/crowngray.png'):require('@/assets/playground_assets/gobletgray.png')" style="margin-top:-5px;">
      </b-badge>
      </h3>
        </div>
        <div class="text-right">
           <h4>
       <b-badge style="background:#BBDEFF;" class="badgeCrown1">
        <span style="color:#0E1116;">Reviewing</span>
      </b-badge>
      </h4>
        </div>
        </div>

        <div v-if="rewards.length>0 && rewards[0].status=='completed'" style=" display: flex;justify-content: space-between;">
          <div class="text-left"  @click="openmodal=true" >
           <h3>
       <b-badge style="background:#8CE0B8" class="badgeCrown1 cursor-pointer">
       <span style="color:#0E1116;">{{item.rewards+" "}}</span>
       <img :src="item.typeCoin.value==1?require('@/assets/images/illustration/crown.svg'):require('@/assets/images/illustration/goblet.svg')" style="margin-top:-5px;"/>
      </b-badge>
      </h3>
        </div>
        </div>
        </b-card-body>
      </b-card>
      <b-modal
     v-model="openmodal"
     title="Share Proof"
     
     hide-footer
     centered
     >
    <b-row class="mb-1 mt-1">
      <b-col cols="12">
        <div class="text-center">
           <b-badge style="background:#8CE0B8">
            <div class="mt-1">
              <img :src="item.typeCoin.value==1?require('@/assets/images/illustration/crown.svg'):require('@/assets/images/illustration/goblet.svg')" style="margin-top:-5px;"/> 
            </div>
            <div style="margin-top:5px;">
                <span style="color:#0E1116;" class="font-weight-bolder">{{item.rewards}}</span>
            </div>
      </b-badge>
           
      </div>
      </b-col>
      
      <b-col cols="12">
          <div class="text-center mt-1">
        <h2>{{item.title}}</h2>
          </div>
      </b-col>
      <b-col cols="12">
        <div class="text-center mt1">
        <span v-html="item.description"></span>
        </div>
      </b-col>
      <b-col cols="12">
         <div v-if="item.type.value==1" class="text-center mt-2">
              <b-button
                block
            class="backgroundBtn"
        style="padding:18px 80px;"
        pill
           target="_blank"
           @click="openUrl(item.type.url)"
           :disabled="isDisabled()">
          {{$t("curlang")=='en'?'Open URL':'Abrir URL'}}
        </b-button>
        </div>
          <div v-if="item.type.value==4" class="text-center mt-2">
             <b-form-group label="Submit your Quest URL">
      <b-form-input
      v-model="urlu"
      placeholder="https://"
      />
    </b-form-group>
              <b-button
                block
            class="backgroundBtn mt-2"
        style="padding:18px 80px;"
         pill
         @click="uploadURL"
           :disabled="isDisabled()">
          {{$t("curlang")=='en'?'Submit Quest':'Submit Quest'}}
        </b-button>
        </div>
        <div  v-if="item.type.value==2 || item.type.value==3" class="text-center mt-2">
         <b-button
              block
            class="backgroundBtn"
        style="padding:18px 80px;"
        pill
           :disabled="isDisabled()"
            @click="btnCLick"
          >
             <feather-icon
            icon="UploadIcon"
            size="16"
          />
          {{$t("curlang")=='en'?'Upload Proof':'Cargar Imagen'}}
        </b-button>
        <b-form-file
    v-model="imagen"
    type="file"
    ref="file"
    style="display:none"
    name="vue-file-input"
    :id="'fileInput'+item.id"
    @input="readURL"
    :disabled="false"
  />
        </div>
      </b-col>
    </b-row>
    </b-modal>
    </div>
</template>

<script>
import {
  BCard, BCardText,BCardHeader,BButton, BRow, BCol, BImg, BCardBody, BCardTitle, BCardSubTitle, BLink,
  BBadge,BFormFile,VBTooltip,BFormGroup,BFormInput,BFormTextarea,BContainer,BCardFooter,BModal
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SellerService from "@/services/SellerService";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    props:["item","idquest","rewards"],
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,BFormInput,
    BImg,BCardHeader,BBadge,BFormFile,
    ToastificationContent,BFormGroup,BFormTextarea,BContainer,BCardFooter,BModal
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  data(){
    return{
      imagen:null,
      imagenFile:null,
      comment:null,
       message: null,
       openmodal:false,
       urlu:null
    }
  },
  mounted(){
    if (this.item.type.value==3) {
    this.getComments()
    }
  },
  methods:{
    getComments:async function(){
      const cm=(await SellerService.getComments(JSON.parse(localStorage.getItem("auth")).discordId,this.item.type.category)).data
      this.message=this.$t('curlang')=='en'?cm.comment:cm.commentes
    },
    openUrl:async function(url){
      const vm=this
      window.open(url,'_blank');
       const insert=(await SellerService.insertRewardsUser(JSON.parse(localStorage.getItem("auth")).discordId,this.idquest,
       this.item.id,''))
        this.$swal({
            title:vm.item.typeCoin.value==1?"Great Crowns Gained":"Great Goblets Gained",
            text: "",
            icon: "success",
             allowOutsideClick: false,
            showClass: {
              popup: "animate__animated animate__fadeIn",
            },
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then(result => {
        if (result.value) {
                vm.$router.go(0)
         }
      });
    },
    btnCLick:function(){
      document.getElementById("fileInput"+this.item.id).click()
    },
      readURL: function(input) {
        const vm=this
    if (input) {
        var reader = new FileReader();
        reader.readAsDataURL(input);
        reader.onload =async function(e) {
          vm.imagen = e.target.result;
          vm.imagenFile = input;         
         const insertimg=(await SellerService.insertRewardsImgUser(JSON.parse(localStorage.getItem("auth")).discordId,vm.idquest,
       vm.item.id,vm.imagenFile))
           vm.$swal({
            title:vm.item.typeCoin.value==1?"Great Crowns Pending":"Great Goblets Pending",
            text:"Your crowns are pending wait for our staff to approve your award",
            icon: "success",
             allowOutsideClick: false,
            showClass: {
              popup: "animate__animated animate__fadeIn",
            },
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then(result => {
        if (result.value) {
                vm.$router.go(0)
         }
      });
        };
      }
    },
    uploadURL:async function(){
      const vm=this
      if (this.urlu) {
        const insert=(await SellerService.insertRewardsUser(JSON.parse(localStorage.getItem("auth")).discordId,this.idquest,
        this.item.id,this.urlu))
         vm.$swal({
            title:vm.item.typeCoin.value==1?"Great Crowns Pending":"Great Goblets Pending",
            text:"Your crowns are pending wait for our staff to approve your award",
            icon: "success",
             allowOutsideClick: false,
            showClass: {
              popup: "animate__animated animate__fadeIn",
            },
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then(result => {
        if (result.value) {
                vm.$router.go(0)
         }
      });
      }
      else{
        alert("Insert")
      }
    },
  
    isDisabled:function()  {
      try {
        if (this.rewards.length>0)
        {return true}
      } catch (error) {
        return false
      }
    },
     onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Text copied',
          icon: 'BellIcon',
        },
      })
    },
      onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failed to copy texts!',
          icon: 'BellIcon',
        },
      })
    },

  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
.backgroundBtn{
background: linear-gradient(87.2deg, rgba(164, 136, 255, 0.8) 2.33%, rgba(74, 44, 174, 0.83) 51.02%, #27088E 94.9%);
 border: transparent;
}
.badgeCrown1{
flex-direction: row;
align-items: center;
padding: 12px 9px;
gap: 5px;
background: #181C21;
border-radius: 8px;
}
.cardStore {
overflow: hidden;
&:hover {
      transform: scale(1.05);
    box-shadow: 0px 0px 10px #82868b !important;
    }
}
</style>

